import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Provider } from 'react-redux';
import store from '../store';
import { NormalizeGlobalStyle, GlobalStyle } from '../components/global';
import { Helmet } from 'react-helmet';
import favicon from '../assets/favicon.ico';
import { ga4Content } from '../util/data/ga4';


/**
 * Import ProximaNova `@font-face` declarations into our main layout.
 * Make sure to include this CSS file into any new layouts we may make.
 * This is important that this is a real CSS file rather than a
 * styled-component `createGlobalStyle()` call.
 *
 * When using the JS version, we'd see flickering of our fonts when interacting
 * with some functional component on the page (expanding sticky ISI, toggling main nav, etc.).
 * By using a real CSS file, we don't run into any of those re-rendering / flickering issues.
 */
import '../fonts/proxima-nova-font-faces.css';

// graphQL get site site metadata from gatsby-config.js
// TODO...maybe break this out into another file
const useSiteMetadata = () => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        siteURL
                    }
                }
            }
        `
    );
    return site.siteMetadata;
};

function NoLayout({ children, pageMeta, location }) {
    // get object metadata onject from gatsby-config.js
    const { siteURL } = useSiteMetadata();

    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang: 'en',
                }}
            >
                <meta charSet="utf-8" />
                <title>{pageMeta ? pageMeta.title : ''}</title>
                <meta name="description" content={pageMeta ? pageMeta.description : ''} />
                <meta name="keywords" content={pageMeta ? pageMeta.keywords : ''} />
                <link rel="canonical" href={`${siteURL}${location ? location.pathname : ''}`} />
                <link rel="icon" href={favicon} type="image/x-icon"></link>
                {/* OneTrust Cookies Consent  */}
                <script
                    src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                    type="text/javascript"
                    charset="UTF-8"
                    data-domain-script={
                        process.env.GATSBY_PRODUCTION_BUILD
                            ? 'fc3238a9-c92f-40bc-a84b-b8db8222b47f'
                            : '51b4128c-1629-4388-901a-b9f13b010cfe-test'
                    }
                ></script>
                <script type="text/javascript">{`function OptanonWrapper() { }`}</script>
                {/* OneTrust Cookies Consent end */}

                {/* dataLayer initialization for properly capturing page url on entry */}
                <script type="text/javascript">{`
                    window.dataLayer = window.dataLayer || []; 
                    window.dataLayer.push({
                        event: "initial_page_load",
                        page_url: window.location.href.replace(window.location.origin, '')
                    });
                `}</script>
                {/* end dataLayer initialization for properly capturing page url on entry */}

                {/* begin JSON-LD page info */}
                {/* Because Gatsby + Helmet are like helicopter parents, we can't just plop the 
                all the schema tags in one chunk. So we have to do it in this ugly way  */}
                {pageMeta && pageMeta.schemaJsonLD && pageMeta.schemaJsonLD.map(script => <script type="application/ld+json">{script}</script>)}                
                {/* end JSON-LD */}
                {/* Google Tag Manager */}
                <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-NMH84D3');`}</script>
                {/* End Google Tag Manager */}
                {/* GA4 Taxonomy */}
                {
                    location.pathname === "/prescribing-information/" &&       <script type="text/javascript">
                    {`
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({"ga4": ${JSON.stringify(ga4Content[location.pathname])}});
                    `}
                </script>
                }
          
                {/* End GA4 Taxonomy */}
            </Helmet>
            {/* Keep global styles outside the Provider, they should never re-render */}
            <NormalizeGlobalStyle />
            <GlobalStyle />
            {/**
             * Ensure that the Redux `Provider` is at (or near) the top of our component tree
             * so that child components can access the store.
             */}
            <Provider store={store}>
                <main>{children}</main>
            </Provider>
        </>
    );
}

export default NoLayout;
